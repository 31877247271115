/* eslint-disable max-len */
import React from 'react';

interface FlagENProps {
    /**
     * A string representing the CSS class to be applied to the FlagENIcon element to override its default styling.
     */
    className?: string;
    /**
     * A number or string representing the height of the FlagENIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the FlagENIcon element.
     */
    width?: number | string;
}

/**
 * The `FlagEN` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `FlagENProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the FlagENIcon element to override its default styling.
 * @param props.height    A number or string representing the height of the FlagENIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the FlagENIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const FlagENComponent = <FlagEN className="myClass" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const FlagEN = React.forwardRef<SVGSVGElement, FlagENProps>((props, ref) => {
    const {className, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 60 30"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <clipPath id="a">
                <path d="M0 0v30h60V0z" />
            </clipPath>
            <clipPath id="b">
                <path d="M30 15h30v15zv15H0zH0V0zV0h30z" />
            </clipPath>
            <g>
                <path d="M0 0v30h60V0z" fill="#012169" />
                <path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6" />
                <path d="M0 0l60 30m0-30L0 30" stroke="#C8102E" strokeWidth="4" />
                <path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10" />
                <path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6" />
            </g>
        </svg>
    );
});

FlagEN.displayName = 'FlagEN';
FlagEN.defaultProps = {
    className: '',
    height: 24,
    testId: 'FlagDE',
    width: 24
};